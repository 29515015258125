import {FC, ReactNode} from 'react';
import Link from 'next/link';
import { XIcon } from '@heroicons/react/outline'
import SVGIcon from "@/components/SVG";
// import { LocationMarkerIcon, PhoneIcon } from "@heroicons/react/solid";

interface UserActionAddressProps {
  children?: ReactNode | ReactNode[]
  animation?: string
  addressLineOne?: string
  addressLineTwo?: string
  scrollY: any
}

const UserActionAddress: FC<UserActionAddressProps> = ({
  children,
  animation,
  addressLineOne,
  addressLineTwo,
  scrollY
}) => {

  return (
    <>
      <a
        href="https://goo.gl/maps/trAnCaWamzYeEtFaA"
        className="sm:px-3 py-2 flex items-start items-center rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 no-underline"
      >
        <div
          className="flex-shrink-0 flex items-center justify-center"
        >
          <SVGIcon
            name={"heroiconsMapMarker"}
            fill="#17aaab"
            stroke="#17aaab"
            viewBox="0 0 24 24"
            className={"h-10 w-10"}
          />
          {/*<LocationMarkerIcon*/}
          {/*  className={`${animation === 'wiggle' ? `animate-wiggle` : animation === 'wave' ? `animation-wave` : ""}            */}
          {/*  h-10 w-10 text-[#17AAAB]`}*/}
          {/*/>*/}
        </div>

        <div className="ml-4">
          {scrollY > 100 ? (
            <>
              <p className="hidden sm:flex text-base text-black font-bold">
                Get Directions
              </p>
            </>
          ) : (
            <>
              <p className="hidden sm:block text-sm font-medium text-black">
                {addressLineOne && addressLineOne || "Add addressLineOne"}
              </p>
              <p className="hidden sm:block mt-1 text-base text-black">
                {addressLineTwo && addressLineTwo || "Add addressLineTwo"}
              </p>
            </>
            )}

        </div>
      </a>
    </>
  )
}

export default UserActionAddress
