import {FC, ReactNode} from 'react';
import Link from 'next/link';
import { CalendarIcon } from '@heroicons/react/outline'
import SVGIcon from "@/components/SVG";
// import { CalendarIcon } from '@heroicons/react/solid'
import { useRouter } from "next/router";

interface UserActionBookAppointmentProps {
  children?: ReactNode | ReactNode[]
}

const UserActionBookAppointment: FC<UserActionBookAppointmentProps> = ({children}) => {
  // const {isSidebarOpen, closeSidebar} = useUI()
  const router = useRouter();

  return (
    <>
      <button
        className="animate-[bounce_1s_ease-in-out_3s] bg-[#FF6600] px-3 py-1.5 rounded shadow-lg"
        onClick={() => router.push("/contact")}
      >
        <div
          className="flex items-center"
        >
          <div
            className="flex flex-shrink-0 justify-center"
          >
            <SVGIcon
              name={"heroiconsCalendarDays"}
              fill="none"
              stroke="#000"
              viewBox="0 0 24 24"
              className={"h-6 w-6 text-[#ff6600]"}
            />
            {/*<CalendarIcon className="h-7 w-7 text-[#000]"/>*/}
          </div>

          <div className="ml-4 flex">
            <p className="text-sm font-medium text-black">
              Request Appointment
            </p>
          </div>
        </div>
      </button>
    </>
  )
}

export default UserActionBookAppointment
