import {FC, ReactNode} from "react";
import Link from 'next/link';
import {MailIcon, PhoneIcon} from "@heroicons/react/outline";

interface TwoColumnContentProps {
  children?: ReactNode | ReactNode[]
}
const navigation = {
  aboutUs: [
    { name: 'Our Philosophy', href: '/about/our-philosophy' },
    { name: 'Our Brisbane Dentists', href: '/about/brisbane-dentist-team' },
    { name: 'Dr. Vincent Wan', href: '/about/dr-vincent-wan' },
    { name: 'Dr. Allen Wan', href: '/about/dr-allen-wan' },
  ],
  popular: [
    { name: 'Our Blog', href: '/our-blog' },
    { name: 'FAQs', href: '/faqs' },
    { name: 'Contact', href: '/contact' },
  ],
  resources: [
    { name: 'Our Blog', href: '/our-blog' },
    { name: 'FAQs', href: '/faqs' },
    { name: 'Contact', href: '/contact' },
  ],
  dentalServicesGroupA: [
    { name: 'Dental Checkup', href: '/dental-services/dental-checkup' },
    { name: 'Teeth Cleaning', href: '/dental-services/teeth-cleaning' },
    { name: 'Tooth Mousse', href: '/dental-services/tooth-mousse' },
    { name: 'Sedation & Anxiety', href: '/dental-services/sedation-anxiety' },
    { name: 'Aligner Orthodontics', href: '/dental-services/aligner-orthodontics' },
    { name: 'Dental Veneers', href: '/dental-services/dental-veneers' },
    { name: 'Invisible Braces', href: '/dental-services/invisible-braces' },
  ],
  dentalServicesGroupB: [
    { name: 'Teeth Whitening Brisbane', href: '/dental-services/teeth-whitening-brisbane' },
    { name: 'Quick Straight Teeth', href: '/dental-services/quick-straight-teeth' },
    { name: 'Dental Bridges Brisbane', href: '/dental-services/dental-bridges-brisbane' },
    { name: 'Dental Crowns', href: '/dental-services/dental-crowns-brisbane' },
    { name: 'Dental Fillings Brisbane', href: '/dental-services/dental-fillings-brisbane' },
    { name: 'Dental Implants', href: '/dental-services/dental-implants-brisbane' },
    { name: 'Dentures', href: '/dental-services/dentures' },
  ],
  dentalServicesGroupC: [
    { name: 'Sports Mouthguards', href: '/dental-services/sports-mouthguards' },
    { name: 'Teeth Clenching & Grinding', href: '/dental-services/teeth-clenching-grinding' },
    { name: 'Snoring & Sleep Apnoea', href: '/dental-services/snoring-sleep-apnoea' },
    { name: 'Tooth Wear', href: '/dental-services/tooth-wear' },
    { name: 'Emergency Dentist', href: '/dental-services/emergency-dentist' },
    { name: 'General Dental Extractions', href: '/dental-services/general-dental-extractions' },
    { name: 'Root Canal Treatment', href: '/dental-services/root-canal-treatment' },
    { name: 'Wisdom Teeth & Other Extractions', href: '/dental-services/wisdom-teeth-other-extractions' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/static/pdf/privacy-policy.pdf' },
    { name: 'Medical History Form', href: '/static/pdf/medical-history-form.pdf' },
    { name: 'Terms & Conditions', href: '/static/pdf/terms-and-conditions.pdf' },
    { name: 'Promotion Terms', href: '/static/pdf/promotion-terms-and-conditions.pdf' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

const Footer: FC<TwoColumnContentProps> = ({children}) => {
  return (
    <footer className="bg-[#070707]" aria-labelledby="footer-heading">

      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="max-w-7xl mx-auto py-12 lg:py-16 text-center sm:text-start">

        <div className="xl:grid xl:grid-cols-4 xl:gap-4 space-y-4 sm:space-y-0">

          <div className="mt-8 xl:mt-0">

            <p className="mt-4 text-base text-gray-300 font-bold">
              Central Brisbane Dental
            </p>

            <p className="mt-2 text-base text-gray-300">
              Suite 5, Ground Floor, Manor Apartments
            </p>

            <p className="mt-2 text-base text-gray-300">
              289 Queen St, Brisbane, QLD, 4000
            </p>

            <div className="mt-6">
              <dt className="sr-only">Phone number</dt>
              <dd className="flex justify-center sm:justify-start">
                <PhoneIcon className="flex-shrink-0 h-6 w-6 text-[#17aaab]" aria-hidden="true"/>
                <span className="ml-3 text-white">(07) 3221 8816</span>
              </dd>
            </div>

            <div className="mt-3">
              <dt className="sr-only">Email</dt>
              <dd className="flex justify-center sm:justify-start">
                <MailIcon className="flex-shrink-0 h-6 w-6 text-[#17aaab]" aria-hidden="true"/>
                <span className="ml-3 text-white">enquire@centralbrisbanedental.com.au</span>
              </dd>
            </div>

          </div>

          <div className="grid grid-cols-1 gap-4 xl:col-span-1">

            <div className="md:grid md:grid-cols-2 md:gap-2">

              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">About Us</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.aboutUs.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mt-4 md:mt-0">Popular Pages</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.popular.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mt-4">Legal</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

            </div>

          </div>

          <div className="grid grid-cols-1 gap-4 xl:col-span-2">

            <div className="md:grid md:grid-cols-3 md:gap-2">

              <div className="mt-4 md:mt-0">
                <h3 className="text-xs font-semibold text-gray-400 tracking-wider uppercase">Dental Services</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.dentalServicesGroupA.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-4 md:mt-0">
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.dentalServicesGroupB.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-4 md:mt-0">
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.dentalServicesGroupC.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white no-underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

            </div>

          </div>

        </div>

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2022 Central Brisbane Dental, All Rights Reserved.
          </p>

          <p
            className="text-lg md:order-2"
          >
            <a
              href="https://reactdiv.io/"
              className="text-[#e1e1e1] no-underline"
              target="_blank"
            >
              Website development by <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#BC1521] via-[#DA4B10] to-[#EBBA21]">reactDiv</span>.io
            </a>
          </p>
        </div>

        <p className="mt-4 text-sm text-gray-400">
          Any surgical or invasive procedure carries risks. Before proceeding, you should seek a second opinion from an appropriately qualified health practitioner.
        </p>

      </div>

    </footer>
  )
}

export default Footer
