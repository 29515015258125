import {FC, useState} from 'react';
import classnames from 'classnames';
import {HTMLInputAttributes} from '@/modules/core/reactDivAPI/types/HTMLInputAttributes';
// @ts-ignore

import {MegaphoneIcon, XMarkIcon} from '@heroicons/react/24/outline';


type SVGIcon = {
  name: string;
  fill: string;
  stroke: string;
  viewBox: string;
  className: string;
}

const SVGIcon: FC<SVGIcon> = ({
  name,
  fill,
  stroke,
  viewBox,
  className,
  ...props
}) => {

  return (
    <svg
      className={className}
      fill={fill}
      stroke={stroke}
      viewBox={viewBox}
      {...props}
    >
      <use xlinkHref={`/static/images/sprites.svg#${name}`} />
    </svg>
  );
};

export default SVGIcon;
