import {FC, ReactNode} from 'react';
import Link from 'next/link';
import {PhoneIcon} from "@heroicons/react/solid";
import SVGIcon from "@/components/SVG";

interface UserActionPhoneProps {
  children?: ReactNode | ReactNode[]
  animation?: string
  phoneNumber?: string
  telLink?: string
}

const UserActionPhone: FC<UserActionPhoneProps> = ({
  children,
  animation,
  phoneNumber,
  telLink
}) => {

  return (
    <>
      <a
        href={`tel:${telLink}`}
        className="sm:px-3 py-2 flex items-start items-center rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 no-underline"
      >
        <div
          className="flex-shrink-0 flex items-center justify-center"
        >
          <SVGIcon
            name={"heroiconsPhone"}
            fill="#17aaab"
            stroke="#17aaab"
            viewBox="0 0 24 24"
            className={"h-10 w-10"}
          />
          {/*<PhoneIcon*/}
          {/*  className={`*/}
          {/*    ${animation && `animate-${animation}` || ""} h-10 w-10 text-[#17AAAB]*/}
          {/*  `}*/}
          {/*/>*/}
        </div>

        <div className="ml-4">
          <p className="hidden sm:block text-base text-black font-bold">
            {phoneNumber && phoneNumber || "add phoneNumber"}
          </p>
        </div>
      </a>
    </>
  )
}

export default UserActionPhone
