import {FC, ReactNode, useState} from 'react';
import Link from "next/link";
import Image from "next/image";
import UserActionAddress from "@/components/centralbrisbanedental/userActions/Address/Address";
import UserActionBookAppointment from "@/components/centralbrisbanedental/userActions/BookAppointment/BookAppointment";
import UserActionPhone from "@/components/centralbrisbanedental/userActions/Phone/Phone";

interface TopbarStyles {
  background: string;
}

interface Topbar {
  scrollY: any;
  style?: TopbarStyles;
  children?: ReactNode | ReactNode[];
}

const Topbar: FC<Topbar> = ({
  scrollY,
  style,
  children
}) => {

  return (
    <section
      id="quick-links-bar"
      className={`${style && style.background && style.background || "bg-white"}`}
    >

      <div
        className={`container mx-auto flex ${scrollY > 100 ? "sm:justify-between" : "sm:justify-end"}`}
      >

        {/* Logo for scrolled position */}
        <div
          className={`hidden sm:flex sm:basis-1/3 items-center transition-all ease-in-out ${scrollY > 100 ? " block" : " hidden"}`}>
          <Link
            href="/"
          >
            <a>
              <Image
                src={"/static/images/logo/central-brisbane-dental-no-toothy.webp"}
                width="330px"
                height="30px"
              />
            </a>
          </Link>

        </div>

        <div
          className="basis-full sm:basis-2/3 flex flex-row justify-between sm:justify-end items-center px-2 sm:px-4 space-x-4">
          {/* Address */}
          <UserActionAddress
            scrollY={scrollY}
            animation="wave"
            addressLineOne="Suite 5, Ground Floor Manor Apartments"
            addressLineTwo="289 Queen St, Brisbane, QLD, 4000"
          />

          {/* Call */}
          <UserActionPhone
            animation="pulse"
            phoneNumber="(07) 3221 8816"
            telLink="+61732218813"
          />

          {/* Book Appointment */}
          <UserActionBookAppointment />

        </div>

      </div>

    </section>
  );
};

export default Topbar;
